import React, { useContext } from 'react';
import ImageGatsby from 'gatsby-plugin-sanity-image';

import * as style from 'styles/components/merch/page.module.scss';
import { useAllMerchItems, useAllMerchCategories } from 'data/queries/useAllMerch';
import useMerchPage from 'data/queries/useMerchPage';
import TextEditorRender from 'components/utils/TextEditorRender';
import Slider from 'components/utils/Slider';
import Image from 'components/utils/Image';
import FormattedMessage, { getFormattedMessage } from 'components/utils/FormattedMessage';
import { LangContext } from 'context/LangContext';

import MerchItem from './Item';

const MerchPage = () => {
    const { lang } = useContext(LangContext);

    const page = useMerchPage();
    const merchCategories = useAllMerchCategories();
    const merchItems = useAllMerchItems();
    const [filteredCategory, setFilteredCategory] = React.useState(null);
    const itemNumberByPage = 9;
    const [maxItems, setMaxItems] = React.useState(itemNumberByPage);

    const filteredMerchItems = () =>
        merchItems.filter(
            (item) => item.category.id === filteredCategory || filteredCategory === null,
        );

    const filteredMerchItemsPaginated = () => filteredMerchItems().slice(0, maxItems);

    return (
        <div className={`${style.shopPage}`}>
            <div className={`${style.shopPage__header}`}>
                <div className={`${style.shopPage__header__left}`}>
                    <p
                        className={style.shopPage__header__left__bg}
                        data-text="HammarbyHammarby"
                        aria-hidden
                    >
                        HammarbyHammarby
                    </p>
                    <p
                        className={style.shopPage__header__left__bg}
                        data-text="marbyHammarbyHam"
                        aria-hidden
                    >
                        marbyHammarbyHam
                    </p>
                    <p
                        className={style.shopPage__header__left__bg}
                        data-text="FotbollFotboll"
                        aria-hidden
                    >
                        FotbollFotboll{' '}
                    </p>
                    <div className={style.shopPage__header__left__content}>
                        <h1 className="title-xl title-md-mobile text-bold">{page.headerTitle}</h1>
                        <p className="text-xl">{page.headerSubtitle}</p>
                        {page.headerLinkBtn.map((item, index) => (
                            <a
                                key={index}
                                href={item.url}
                                target={item.external ? '_blank' : '_self'}
                                rel="noreferrer"
                                className="cta-white"
                            >
                                {item.title}
                            </a>
                        ))}
                    </div>
                </div>
                <div className={style.shopPage__header__right}>
                    <Image image={page.headerImage} height={600} width={700} />
                </div>
            </div>
            <div className="wrapper">
                <div className={`${style.shopPage__intro}`}>
                    <h2 className="title-sm text-semibold">{page.contentTitle}</h2>
                    <TextEditorRender content={page._rawContent} />
                </div>
            </div>
            <ul className={`${style.shopPage__filters} tabs wrapper`}>
                <li>
                    <button
                        className={filteredCategory === null ? 'active' : ''}
                        onClick={() => {
                            setFilteredCategory(null);
                            setMaxItems(itemNumberByPage);
                        }}
                        type="button"
                        aria-label={getFormattedMessage('merch.allProducts', lang)}
                    >
                        <FormattedMessage id="merch.allProducts" />
                    </button>
                </li>
                {merchCategories.map((item, index) => (
                    <li key={index}>
                        <button
                            className={item.id === filteredCategory ? 'active' : ''}
                            onClick={() => {
                                setFilteredCategory(item.id);
                                setMaxItems(itemNumberByPage);
                            }}
                            type="button"
                            aria-label={item.title[lang.translationKey]}
                        >
                            {item.title[lang.translationKey]}
                        </button>
                    </li>
                ))}
            </ul>
            <div className="wrapper">
                <ul className={`${style.shopPage__items}`}>
                    {filteredMerchItemsPaginated().map((item, index) => (
                        <li key={index}>
                            <MerchItem item={item} />
                        </li>
                    ))}
                </ul>
                {filteredMerchItems() > filteredMerchItemsPaginated() && (
                    <button
                        className={`${style.shopPage__load_more} cta-white-sec`}
                        onClick={() => {
                            setMaxItems(maxItems + itemNumberByPage);
                        }}
                        type="button"
                        aria-label={getFormattedMessage('global.loadMore', lang)}
                    >
                        <FormattedMessage id="global.loadMore" />
                    </button>
                )}
                <div className={`${style.shopPage__partners} block-paddings`}>
                    {page.sponsors.map((item, index) => (
                        <a
                            key={index}
                            className={`${style.shopPage__partners__item}`}
                            href={item.url}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <div className={`${style.shopPage__partners__item__img} center`}>
                                <ImageGatsby
                                    {...item.backgroundImage}
                                    alt={item.title}
                                    width={300}
                                    className={style.bg}
                                />
                                <Image image={item.logo} className={style.logo} />
                            </div>
                            <div className={style.shopPage__partners__item__txt}>
                                <p className="text-xl-mobile title-xs text-bold">{item.title}</p>
                                <p className="text-md">{item.text}</p>
                                <div className="right">
                                    <i className="ico-arrow" aria-hidden />
                                </div>
                            </div>
                        </a>
                    ))}
                </div>
            </div>
            <div className={`${style.shopPage__people} bg block-paddings`}>
                <Slider
                    title={page.sliderTitle}
                    hasVariableWidth
                    slidesDesktop={2}
                    slidesTablet={2}
                    slidesMob={1}
                >
                    {page.slides.map((item, index) => (
                        <div key={index}>
                            <img src={`${item.image.asset.url}?h=420`} alt="" />
                            <p className="text-sm text-semibold">{item.text}</p>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default MerchPage;
