import { useStaticQuery, graphql } from 'gatsby';

const useMerchPage = () => {
    const {
        sanityConfigGlobal: { merchandisingConfig: merchPage },
    } = useStaticQuery(graphql`
        query {
            sanityConfigGlobal {
                merchandisingConfig {
                    headerTitle
                    headerSubtitle
                    headerLinkBtn {
                        url
                        title
                        external
                    }
                    headerImage {
                        alt
                        image {
                            ...ImageWithPreview
                        }
                    }
                    contentTitle
                    _rawContent
                    sponsors {
                        backgroundImage {
                            ...ImageWithPreview
                        }
                        logo {
                            alt
                            image {
                                ...ImageWithPreview
                            }
                        }
                        title
                        text
                        url
                    }
                    sliderTitle
                    slides {
                        alt
                        image {
                            asset {
                                url
                            }
                        }
                        text
                    }
                }
            }
        }
    `);

    return merchPage;
};

export default useMerchPage;
